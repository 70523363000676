import React from "react";
import "./HomeWhy.css";
const HomeWhyHead = () => {
  return (
    <div className="homeWhyHead">
      <h2>Why R18 Education</h2>
    </div>
  );
};

export default HomeWhyHead;
