import React from 'react'
import AboutUsHeader from './AboutUsHeader/AboutUsHeader'

const AboutUsPage = () => {
  return (
    <div className='homePage'>
      <AboutUsHeader/>
    </div>
  )
}

export default AboutUsPage
