import React from 'react'
import "./HomeWhy.css"
import HomeWhyMain from './HomeWhyMain'
const HomeWhy = () => {
  return (
    <div className='homeWhyContainer'>
      <HomeWhyMain/>
    </div>
  )
}

export default HomeWhy
