import React from 'react'
import "./AboutUsHeader.css"
const AboutUsHeader = () => {
  return (
    <div className='aboutUsHeader'>
      
    </div>
  )
}

export default AboutUsHeader
